import React from 'react';
import PropTypes from 'prop-types';

// style components
import Table from '../../../components/Table/Table';
import Status from '../Status/Status';
import getStatus from '../../libs/getStatus.order';
import ExtendedOrderRow from '../ExtendedOrderRow/ExtendedOrderRow';

// data
import columns from './data/columns';

// libs
import toPrettyDate from '../../../lib/toPrettyDate';
import getHighestDeliveryDate from '../../libs/getHighestDeliveryDate.order';

const OrdersTable = ({sort, rows, onSort, rowClicked, onStatusChange}) => {
  return (
    <Table
      columns={columns}
      size="medium"
      headerSize="large"
      noMargin={false}
      labelSpace={false}
      onSort={onSort}
      sort={sort}
      theme="white"
    >
      {(TableRow) =>
        rows.map((row) => (
          <TableRow
            key={row._id}
            id={row._id}
            noMargin={false}
            rowClick={rowClicked}
            dropdown={
              <ExtendedOrderRow
                orderId={row._id}
                status={row.status}
                nestedRows={row.orderRows}
                onStatusChange={onStatusChange}
              />
            }
          >
            {(TableCol) => [
              <TableCol key="orderNo" span={2} align="center">
                {row.orderNumber}
              </TableCol>,
              <TableCol key="ediOrderNo" span={2} align="center">
                {row.orderRows[0]?.documentId}
              </TableCol>,
              <TableCol key="createdAt" span={3} align="center">
                {toPrettyDate(row.createdAt)}
              </TableCol>,
              <TableCol key="estimatedDelivery" span={2} align="center">
                {row.orderRows && row.orderRows.length
                  ? getHighestDeliveryDate(row.orderRows)
                  : '-'}
              </TableCol>,
              <TableCol key="status" align="center" span={2}>
                <Status props={getStatus(row.status)} />
              </TableCol>,
              <TableCol key="price" align="center" span={2}>
                {row.price?.toFixed(2)} €
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
  );
};

OrdersTable.propTypes = {
  rows: PropTypes.array,
  sort: PropTypes.func,
  onSort: PropTypes.func,
  rowClicked: PropTypes.func,
  onStatusChange: PropTypes.func,
};

export default OrdersTable;
